<template>
    <div class="row my-2">
        <div class="col-12 d-flex justify-content-center">
            <span class="text-primary"> Días transcurridos {{ daysAgo }} </span>
        </div>
        <div class="offset-md-2 col-md-4 col-12 mt-1 d-flex flex-column">
            <label for="begin">Fecha inicial</label>
            <span>{{ begin }}</span>
        </div>
        <div class="col-md-4 col-12 mt-1 d-flex flex-column">
            <label for="end">Fecha final</label>
            <span>{{ end }} </span>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
    name: "MouthAgoFilter",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Object,
            required: false,
            default: () => ({
                end: DateTime.now()
                    .setLocale("es-CO")
                    .toFormat("cccc, dd LLLL, yyyy"),
                begin: DateTime.now()
                    .setLocale("es-CO")
                    .plus({ days: -1 })
                    .toFormat("cccc, dd LLLL, yyyy")
            })
        }
    },
    data: () => ({
        daysAgo: 1,
        begin: "",
        end: ""
    }),
    watch: {},
    mounted() {
        this.changeInput();
    },
    methods: {
        changeInput() {
            const end = DateTime.now().setLocale("es-CO");
            const begin = end.set({
                day: 1,
                month: end.month
            });
            this.daysAgo = end.diff(begin, "days").days + 1;
            this.begin = begin.toFormat("cccc, dd LLLL, yyyy");
            this.end = end.toFormat("cccc, dd LLLL, yyyy");
            this.$emit("change", {
                begin: begin.toFormat("yyyy-MM-dd"),
                end: end.toFormat("yyyy-MM-dd")
            });
        },
        setInput(value) {
            this.begin = String(value.begin);
            this.end = String(value.end);
        }
    }
};
</script>
